<template>
        <section class="section" :class="{'reversed': reverse}">
            <img :src="img('shape.png')" alt="404" :class="{'section__shape': !reverse, 'reversed__shape': reverse}">
            <div class="section__img">
                <div>
                    <img :src="img(imgSrc)" alt="">
                </div>
            </div>
            <div class="section__text">
                <Intersect @enter="inters(true)" @leave="inters(false)">
                <div class="text animate" :class="{'animated': ints}">
                    <div class="text__label">
                        {{ title }}
                    </div>
                    <div class="text__title">
                        {{ text }}
                    </div>
                </div>
                </Intersect>
            </div>
        </section>
</template>

<script>
import Intersect from "vue-intersect"
export default {
    data() {
        return {
            ints: false        
        }
    },

    props: {
        reverse: {
            type: Boolean,
            required: false,
            default: false
        },

        title: {
            type: String,
            required: true
        },

        text: {
            type: String,
            required: true
        },

        imgSrc: {
            type: String,
            required: true
        },
    },

    methods: {
        img(imgName) {
            return require('@/assets/images/' + imgName)
        },

        inters(bool) {
            if (bool) {
                this.ints = true
            } else {
                this.ints = false
            }
        }
    },

    components: {
        Intersect
    }
}
</script>

<style lang="scss" scoped src="@/assets/styles/section.scss"></style>