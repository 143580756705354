<template>
    <main>
        <div class="logo">
            <img :src="img('logo.png')" alt="">
        </div>
        <div class="body">
            <img :src="img('mainBottom.png')" class="body__absolute-img" alt="">

            <div class="body__centered">
                <div class="left">
                    <div class="init-left">
                        <div class="left__title">
                            AKO CARS<sup>&reg;</sup> mission is to connect people,<br> allowing you to fix and <br>improve your car.
                        </div>
                        <p class="left__subtitle">
                            This app makes saving car's info much easier, and you can receive proposals to get a repair service, without wasting your time
                        </p>
                        <StoreLinks />
                        <!-- <div class="left__store-link">
                            <div class="link">
                                <a href="#">
                                    <img :src="img('googlePlay.png')" alt="">
                                </a>
                            </div>
                            <div class="link">
                                <a href="#" @click.prevent class="disabled">
                                    <img :src="img('appStore.png')" alt="">
                                </a>
                                <span class="cu">
                                    <a :href="links.contact" target="__blank">contact us</a>
                                </span>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="right">
                    <img :src="img('phoneImg1.png')" alt="">
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import StoreLinks from "@/components/StoreLinks.vue";

export default {
    methods: {
        img(imgName) {
            return require('@/assets/images/' + imgName)
        }
    },

    components: {
        StoreLinks
    }
}
</script>

<style lang="scss" scoped src="@/assets/styles/mainSection.scss"></style>
