<template>
    <section class="end-section">
        <div class="end-section__img">
            <img :src="img('end-img.jpg')" alt="404">
        </div>
        <div class="end-section__body">
            <div class="centered">
                <p class="centered__label">
                    Seen Enough Information ?
                </p>
                <div class="centered__text">
                    Download AKO CARS<sup>&reg;</sup> Now
                    <br>&<br>
                    Use it wherever you are
                </div>
                <div class="sclink">
                    <StoreLinks />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import StoreLinks from "@/components/StoreLinks.vue";

export default {
    methods: {
        img(imgName) {
            return require('@/assets/images/' + imgName)
        },
    },

    components: {
        StoreLinks
    }
}
</script>

<style lang="scss" scoped src="@/assets/styles/endSection.scss"></style>
