<template>
    <div id="app">
        <MainSection />
        <Section 
            :title="i.title"
            :text="i.text"
            :imgSrc="i.imgSrc"    
            :reverse="i.reversed"
            v-for="(i, ind) in sections" :key="ind"
        />
        <EndSection />
        <Footer />
    </div>
</template>

<script>
import Main from "@/components/sections/Main.vue";
import Section from "@/components/sections/Section.vue";
import EndSection from "@/components/sections/EndSection.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: 'app',

    data() {
        return {
            sections: [
                {
                    reversed: false,
                    title: 'My cars',
                    text: "Now you can easily make a list of your cars, with their related details",
                    imgSrc: 'phoneImg2.png',
                },
                {
                    reversed: true,
                    title: 'To do',
                    text: "Stay informed by storing all the tasks you want to perform as a To-do list",
                    imgSrc: 'phoneImg3.png',
                },
                {
                    reversed: false,
                    title: 'Proposal',
                    text: "If you need to get something fixed, and have no time to call the mechanic, just share it with the professionals, and you will receive multiple proposals to choose from.",
                    imgSrc: 'phoneImg4.png',
                },
                {
                    reversed: true,
                    title: 'Document',
                    text: "You can avoid the effort of memorizing all the car's documents by uploading them to the app",
                    imgSrc: 'phoneImg5.png',
                },
            ],

            intersections: {
                section0: false,
                section1: false,
                section2: false,
                section3: false,
            }
        }
    },

    methods: {
        inters(bool, target) {
            if (bool) {
                this.intersections[target] = false
            } else {
                this.intersections[target] = true
            }
        }
    },

    components: {
        MainSection: Main,
        Section: Section,
        Footer: Footer,
        EndSection: EndSection
    }
}
</script>